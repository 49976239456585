import React from "react";

const Credit = () => {
  return (
    <div className="credit">
      © Copyright 2024 | Designed and Developed With{" "}
      <i class="bi bi-heart-fill"></i> By
      <a href="fifthwave.in">Fifthwave</a>
    </div>
  );
};

export default Credit;
