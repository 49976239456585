import React from "react";

const Vision = () => {
  return (
    <div className="vision">
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#121212",
        }}
        id="title"
      >
        <span style={{ color: "white" }}>Founder's Vision</span>
        <hr
          style={{
            height: "5px",
            width: "70px",
            borderRadius: "10px",
            backgroundColor: "red",
            border: "none",
          }}
        />
      </div>
      <div className="vision-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div className="vision-img">
            <img src={require("../assets/founder.png")} alt="founder" />
          </div>
          <div
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "800" }}
          >
            Shivanand kashyap <br />
            Founder (0001 CARS)
          </div>
        </div>
        <div className="vision-detail">
          "As the founder of 0001 CARS, my vision is to transform the way people
          perceive car detailing. I believe that every vehicle deserves to look
          its best, reflecting the pride of its owner. My goal is to provide
          exceptional detailing services that not only enhance the appearance of
          cars but also protect their value. With a commitment to quality,
          integrity, and customer satisfaction, I aim to create a welcoming
          environment where every client feels valued and every vehicle receives
          the care it deserves."
        </div>
      </div>
    </div>
  );
};

export default Vision;
