import React from "react";

const Services = () => {
  return (
    <div className="services">
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#121212",
        }}
        id="title"
      >
        <span style={{ color: "white" }}>
          Choose the no 000
          <span style={{ fontFamily: "sans-serif", fontSize: "40px" }}>1 </span>
        </span>
        <hr
          style={{
            height: "5px",
            width: "70px",
            borderRadius: "10px",
            backgroundColor: "red",
            border: "none",
          }}
        />
        <span
          style={{
            fontSize: "20px",
            letterSpacing: "1px",
            color: "#fff",
            marginBottom: "20px",
          }}
        >
          Professional car detailing Services. Same-day Delivery with Pick &
          Drop.
        </span>
        <span
          style={{
            fontSize: "18px",
            color: "#fff",
            marginBottom: "20px",
          }}
        >
          With exceptional craftsmanship, cutting-edge technology,
          <br />
          international-standard products, affordable pricing, eco-friendly
          <br />
          practices, and a dedicated team, 0001 Cars has become one of the most
          <br />
          renowned names in the city's automotive industry. Our reputation is
          <br />
          backed by a growing base of satisfied customers and a rapidly
          <br />
          expanding following on social media.
        </span>
      </div>
      <div className="row">
        <div class="card">
          <img src={require("../assets/ceramic.png")} alt="ceramic " />
          <div className="title">Ceramic Coating with service</div>
          <div className="detail">
            Protect your car for years with just one professional ceramic
            coating including services (2)
          </div>
        </div>
        <div class="card">
          <img src={require("../assets/polish.png")} alt="polish" />
          <div className="title">Polishing (2 layers)</div>
          <div className="detail">
            Correct paint defects, remove scratches. Make your car glossy and
            shiny. Make your car or bike like new.
          </div>
        </div>
        <div class="card">
          <img src={require("../assets/ppf.png")} alt="ppf" />
          <div className="title">PPF</div>
          <div className="detail">
            Save your car’s paint with our advanced PPF technique. PPF solution
            delivered fast increasing your car’s overall value.
          </div>
        </div>

        <div class="card">
          <img src={require("../assets/cleaning.png")} alt="cleaning" />
          <div className="title">Interior & exterior detailing</div>
          <div className="detail">
            Your car interiors talk a lot about you! Keep your car interiors
            safe and clean. Easily remove any stain and dirt from car’s interior
            surfaces.
          </div>
        </div>
        <div class="card">
          <img src={require("../assets/glass-tint.png")} alt="glass-tint" />
          <div className="title">Glass tinting</div>
          <div className="detail">
            Prevent accidents from sun glares and keep your car relatively cool.
            Professional RTO-passed glass tinting service with the best films.
          </div>
        </div>
        <div class="card">
          <img src={require("../assets/painting.png")} alt="painting" />
          <div className="title">Denting painting</div>
          <div className="detail">
            Damage or scratched on the car? No worries! Quickly recover your car
            from damages in the most natural form. Colour match accurately done
            using code to give you near to original colour with smooth
            finishing.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
