import React from "react";

const Loading = () => {
  return (
    <div className="loading">
      <img src={require("../assets/loader.gif")} alt="loader" />
    </div>
  );
};

export default Loading;
