import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-card">
        <div className="footer-logo">
          <img src={require("../assets/logo.png")} alt="logo" />
        </div>
        <div className="footer-detail">
          <div className="details">
            <div>
              Your Premier Automotive Destination in Ranchi. Welcome to 0001
              Cars, your ultimate automotive destination in Ranchi, where we're
              passionate about cars and committed to providing you with a
              complete range of services to meet all your automotive needs.
            </div>
          </div>
          <div className="address">
            <div>
              <b>PHONE :</b>
              <br />+ 91 7769970001
            </div>
            <br />
            <div>
              <b>ADDRESS :</b>
              <br />
              <br />
              <li>GAYATRI NAGAR , KHUNTI , Ranchi , JHARKHAND 835210</li>
              <br />
              <li>Near Argora Chowk , Ranchi , Jharkhand 834002</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
