import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
const Social = () => {
  const [sent, setSent] = useState(false);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setSent(true);
    emailjs
      .sendForm("service_htdbque", "template_1rqdv6u", form.current, {
        publicKey: "Pp9D-54oLxjicbBJi",
      })
      .then(
        () => {
          if (
            window.confirm(
              "Thank you for contacting us . We will get back to you as soon as possible"
            ) === true
          ) {
            window.location.href = "/";
          } else {
            window.location.href = "/";
          }
        },
        (error) => {
          if (
            window.confirm(
              "Some error occured , sorry for the inconvenience caused ."
            ) === true
          ) {
            window.location.href = "/";
          } else {
            window.location.href = "/";
          }
        }
      );
  };
  return (
    <div className="social">
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#fff",
        }}
        id="title"
      >
        <span>CONTACT US</span>
        <hr
          style={{
            height: "5px",
            width: "70px",
            borderRadius: "10px",
            backgroundColor: "red",
            border: "none",
          }}
        />
        <span
          style={{
            fontSize: "20px",
            letterSpacing: "1px",
            color: "#f4f4f4",
            marginBottom: "20px",
          }}
        >
          Contact us for a FREE consultation and avail your social sharing
          <br />
          discounts on select services. Call us or simply fill the below form to
          <br />
          receive a phone call within few minutes.
        </span>
        <span
          style={{
            fontSize: "18px",
            color: "#f4f4f4",
            marginBottom: "20px",
          }}
        >
          Your preferred car detailer in Ranchi with professional car detailing
          <br /> services for any car or bike.
        </span>
        <span
          style={{
            fontSize: "18px",
            color: "#4e4e4e",
            marginBottom: "20px",
          }}
        >
          <div style={{ color: "red" }}>DRIVEN BY LEGENDS!</div>
          <div style={{ color: "white", marginTop: "10px" }}>
            Book online now or call -<br />
            <b>+91 7769970001 | 0001carsstudio@gmail.com</b>
          </div>
          <div className="contact-form">
            <form onSubmit={sendEmail} ref={form} className="form">
              <input type="text" placeholder="Your Name*" name="name" />
              <input type="text" placeholder="Your Email id*" name="email" />
              <input type="text" placeholder="Phone*" name="phone" />
              <input type="text" placeholder="Car Type*" name="type" />
              <input type="text" placeholder="Location*" name="location" />
              <textarea
                rows="6"
                placeholder="Message*"
                name="message"
              ></textarea>
              <br />
              {sent === false ? (
                <button
                  type="submit"
                  className="btn"
                  style={{ border: "none" }}
                >
                  Submit
                </button>
              ) : (
                <img
                  src={require("../assets/loaderr.gif")}
                  alt="load"
                  width="40px"
                  height="40px"
                />
              )}
            </form>
          </div>
        </span>
      </div>
    </div>
  );
};

export default Social;
