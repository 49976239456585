import "./App.css";
import "./responsive.css";
import React, { useEffect, useState } from "react";
import Compare from "./components/Compare";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import "bootstrap-icons/font/bootstrap-icons.css";
import Services from "./components/Services";
import Social from "./components/Social";
import Footer from "./components/Footer";
import Credit from "./components/Credit";
import Loading from "./components/Loading";
import Vision from "./components/Vision";
import Taskbar from "./components/Taskbar";

function App() {
  const [click, setClick] = useState(0);
  const [loading, setLoading] = useState(false);
  function setfunc(e) {
    setClick(e);
  }
  if (click === 0) {
    window.scrollTo(0, 0);
  }
  if (click === 1) {
    window.location.href = "#compare";
  }
  if (click === 2) {
    window.location.href = "#services";
  }
  if (click === 3) {
    window.location.href = "#contact";
  }
  if (click === 4) {
    window.location.href = "#vision";
  }
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  return (
    <>
      <Navbar setfunc={setfunc} />
      <div id="home">
        <Hero />
      </div>
      <div style={{ display: loading === true ? "none" : "block" }}>
        <div id="compare">
          <Compare />
        </div>
        <div id="services">
          <Services />
        </div>
        <div id="vision">
          <Vision />
        </div>
        <div id="contact">
          <Social />
        </div>
        <Footer />
        <Credit />
        <Taskbar />
      </div>
      <div style={{ display: loading === true ? "block" : "none" }}>
        <Loading />
      </div>
    </>
  );
}

export default App;
