import React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import video from "../assets/vid4.mp4";
const Hero = () => {
  useGSAP(() => {
    gsap.from(".hero-content", { scale: 0, delay: 0, duration: 1 });
  });
  return (
    <>
      <video autoPlay muted loop id="hero">
        <source src={video} type="video/mp4" />
      </video>
      <div className="hero">
        <div className="hero-content">
          <h1>
            000
            <span style={{ fontFamily: "sans-serif", fontSize: "50px" }}>
              1{" "}
            </span>
            CARS - Car Detailing Studio{" "}
          </h1>
          <br />
          <h3>Professional Car Detailing in Ranchi, Jharkhand</h3>
        </div>
      </div>
    </>
  );
};

export default Hero;
