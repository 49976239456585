import React from "react";

const Taskbar = () => {
  return (
    <div className="taskbar">
      <div>
        <i
          class="bi bi-instagram"
          onClick={() => {
            window.location.href = "https://www.instagram.com/0001__cars/";
          }}
        ></i>
      </div>
      <div>
        <i
          class="bi bi-whatsapp"
          onClick={() => {
            window.location.href = "https://wa.me/7769970001";
          }}
        ></i>
      </div>
      <div>
        <i
          class="bi bi-envelope"
          onClick={() => {
            window.location.href = "mailto:0001carsstudio@gmail.com";
          }}
        ></i>
      </div>
      <div>
        <i
          class="bi bi-telephone"
          onClick={() => {
            window.location.href = "tel:7769970001";
          }}
        ></i>
      </div>
    </div>
  );
};

export default Taskbar;
