import React, { useState } from "react";
// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";
const Navbar = ({ setfunc }) => {
  // useGSAP(() => {
  //   gsap.from(".navbar", { y: -100, duration: 1, delay: 0 });
  // });
  var show = document.querySelector(".mob-nav");
  const [page, setPage] = useState(0);
  const changeNavbarColor = () => {
    if (window.scrollY < 500) {
      setPage(0);
    }
    if (window.scrollY >= 600) {
      setPage(1);
    }
    if (window.scrollY >= 3110) {
      setPage(2);
    }
    if (window.scrollY >= 4600) {
      setPage(4);
    }
    if (window.scrollY >= 5200) {
      setPage(3);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <div className="navbar">
        <div className="nav-logo">
          <img src={require("../assets/logo.png")} alt="logo" />
        </div>
        <div className="nav-links">
          <ul>
            <li
              className={`${page === 0 && "active"}`}
              onClick={() => {
                setfunc(0);
              }}
            >
              Home
            </li>
            <li
              className={`${page === 1 && "active"}`}
              onClick={() => {
                setfunc(1);
              }}
            >
              Our Work
            </li>
            <li
              className={`${page === 2 && "active"}`}
              onClick={() => {
                setfunc(2);
              }}
            >
              Services
            </li>
            <li
              className={`${page === 4 && "active"}`}
              onClick={() => {
                setfunc(4);
              }}
            >
              Vision
            </li>
            <li
              className={`${page === 3 && "active"}`}
              onClick={() => {
                setfunc(3);
              }}
            >
              Contact
            </li>
            <i
              className="bi bi-list"
              onClick={() => {
                show.style.left = "0%";
              }}
            ></i>
          </ul>
        </div>
      </div>
      <div className="mob-nav">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px 25px",
            borderBottom: "1px solid #4e4e4e",
          }}
        >
          <div className="mob-nav-logo">
            <img src={require("../assets/logo.png")} alt="logo" />
          </div>
          <i
            className="bi bi-x-lg"
            style={{ color: "white", fontSize: "25px" }}
            onClick={() => {
              show.style.left = "-100%";
            }}
          ></i>
        </div>
        <ul>
          <li
            className={`${page === 0 && "active"}`}
            onClick={() => {
              setfunc(0);
              show.style.left = "-100%";
            }}
          >
            Home
          </li>
          <li
            className={`${page === 1 && "active"}`}
            onClick={() => {
              setfunc(1);
              show.style.left = "-100%";
            }}
          >
            Our Work
          </li>
          <li
            className={`${page === 2 && "active"}`}
            onClick={() => {
              setfunc(2);
              show.style.left = "-100%";
            }}
          >
            Services
          </li>
          <li
            onClick={() => {
              setfunc(4);
              show.style.left = "-100%";
            }}
          >
            Vision
          </li>
          <li
            className={`${page === 3 && "active"}`}
            onClick={() => {
              setfunc(3);
              show.style.left = "-100%";
            }}
          >
            Contact
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
