import React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ReactCompareImage from "react-compare-image";
const Compare = () => {
  useGSAP(() => {
    gsap.from("#title", {
      scale: 0,
      duration: 1,
      delay: 0,
    });
  });
  return (
    <div className="compare">
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#fff",
        }}
        id="title"
      >
        <span>Our Work Says It All!</span>
        <hr
          style={{
            height: "5px",
            width: "70px",
            borderRadius: "10px",
            backgroundColor: "red",
            border: "none",
          }}
        />
        <span
          style={{
            fontSize: "18px",
            color: "#f4f4f4",
            marginBottom: "20px",
          }}
        >
          0001 Cars is a customer-focused detailing company specializing in
          <br />
          nano-coating and premium car coating services. We are experts in
          <br />
          cleaning and protecting vehicles across all categories.
        </span>
        <span
          style={{
            fontSize: "18px",
            color: "#f4f4f4",
            marginBottom: "20px",
          }}
        >
          Swipe left <span style={{ color: "red" }}>{`< >`}</span> right and
          feel the difference!
        </span>
      </div>
      <div></div>
      <div className="compare-slider">
        <div className="comp-slide">
          <ReactCompareImage
            leftImage={require("../assets/1.1.jpg")}
            rightImage={require("../assets/1.2.jpg")}
          />
        </div>
        <div className="comp-slide">
          <ReactCompareImage
            leftImage={require("../assets/2.1.jpg")}
            rightImage={require("../assets/2.2.jpg")}
          />
        </div>
        <div className="comp-slide">
          <ReactCompareImage
            leftImage={require("../assets/4.1.jpg")}
            rightImage={require("../assets/4.2.jpg")}
          />
        </div>
      </div>
    </div>
  );
};

export default Compare;
